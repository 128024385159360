import type { WagtailMainMenu } from '@/types';
import Hamburger from '@components/Hamburger';
import { removeBaseUrl } from '@utils/url';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

type Props = {
  data: WagtailMainMenu;
};

function Navigation(props: Props) {
  const { data } = props;
  const hamburgerRef = useRef<HTMLButtonElement | null>(null);
  const router = useRouter();

  useEffect(() => {
    function handleRouteChange() {
      if (hamburgerRef.current) hamburgerRef.current.ariaExpanded = 'false';
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router]);

  if (!data?.items?.length) return null;

  return (
    <nav className='nav' id='main-nav' aria-label='Hauptnavigation'>
      <Hamburger
        onClick={e => {
          e.currentTarget.ariaExpanded = e.currentTarget.ariaExpanded === 'true' ? 'false' : 'true';
        }}
        aria-label='Navigation umschalten'
        aria-controls='main-nav-list'
        aria-expanded='false'
        ref={hamburgerRef}
      />
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <ul role='list' id='main-nav-list' className='nav__list'>
        {data.items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className='nav__list-item' key={`${index}${item.page.id}`}>
            <Link
              href={removeBaseUrl(item.href)}
              className={clsx('nav__link', {
                active: router.asPath === `/${item.page.slug}`,
              })}
            >
              {item.text ?? item.page.title}
            </Link>
            {item.children.length ? (
              // eslint-disable-next-line jsx-a11y/no-redundant-roles
              <ul role='list' className='nav__sub-menu'>
                {item.children.map((child, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className='nav__sub-menu-item' key={`${i}${item.page.id}`}>
                    <Link href={removeBaseUrl(child.href)} className='nav__link'>
                      {child.text ?? child.page.title}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navigation;
