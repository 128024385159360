import type { AppProps } from 'next/app';
import { Roboto_Condensed } from 'next/font/google';
import Layout from '../components/Layout';

import '../styles/style.scss';

const roboto = Roboto_Condensed({
  weight: ['400', '700'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${roboto.style.fontFamily};
        }
      `}</style>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default MyApp;
