import { WagtailMainMenu } from '@/types';
import Navigation from '@components/Navigation';
import Link from 'next/link';
import Logo from '@images/logo.svg';

type Props = {
  menuData?: WagtailMainMenu;
};

function Header(props: Props) {
  const { menuData } = props;

  return (
    <header className='header'>
      <Link href='/' title='Startseite' className='header__logo'>
        <Logo width='auto' height={64} aria-label='Paleo Experts' />
      </Link>
      {menuData ? <Navigation data={menuData} /> : null}
    </header>
  );
}

export default Header;
