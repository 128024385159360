import { removeBaseUrl } from '@/utils/url';
import Link from 'next/link';

type FooterMenuData = {
  id: number;
  title: string;
  link_intern_full_url: string | null;
  link_url: string | null;
};

type FooterData = {
  id: number;
  footer_menu: FooterMenuData[];
};

type Props = {
  data: FooterData | null;
};

function Footer(props: Props) {
  const { data } = props;

  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <footer className='footer'>
      <p className='copyright'>©{date} Neanderthal Museum</p>
      <nav id='footer-navigation'>
        <ul className='footer__menu-list'>
          <li className='footer__menu-list-item'>
            <Link
              href='https://neanderthal.de/en/site-notice.html'
              target='_blank'
              rel='noreferrer noopener'
            >
              Site Notice
            </Link>
          </li>
          <li className='footer__menu-list-item'>
            <Link
              href='https://neanderthal.de/en/Privacy-Policy.html'
              target='_blank'
              rel='noreferrer noopener'
            >
              Privacy-Policy
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;
