import Matomo from '@components/Matomo';
import Head from 'next/head';
import Script from 'next/script';

const isProd = process.env.NODE_ENV === 'production';

function CustomScripts() {
  if (!isProd) return null;

  return (
    <>
      <Matomo />
      {process.env.NEXT_PUBLIC_VINEGAR_ENABLED === 'true' ? (
        <>
          <Head>
            {/* eslint-disable-next-line @next/next/no-css-tags */}
            <link rel='stylesheet' href='/static/vinegar/vinegar.css' />
          </Head>
          <Script
            src='/static/vinegar/standalone.js'
            strategy='afterInteractive'
            nonce='@@=AAABBBCCCZZZ=@@'
          />
        </>
      ) : null}
    </>
  );
}

export default CustomScripts;
